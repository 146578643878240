.main-container {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  justify-content: center;
  align-items: center; /* Centers content vertically (useful if there's extra space) */
  margin: 0 auto;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column; /* Default to vertical layout */
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

@media (min-width: 750px) {
  .container {
    flex-direction: row; /* Switches to horizontal layout on larger screens */
  }
}

.container-small{
  display: flex;
  flex-direction: row; /* Default to vertical layout */
  width: 100%;
  height: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.container-large {
  display: flex;
  flex-direction: column; /* Default to vertical layout */
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.right-align {
  display: flex;
  flex-direction: row; /* Default to vertical layout */
  margin-top: auto;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 0;
  height: 20px;
}

.text-container {
  width: 100%; /* Full width on small screens */
  padding: 20px;
  text-align: center;
  align-items: center;
}

@media (min-width: 750px) {
  .text-container {
    flex: 1;
    text-align: left;
  }
}

.image-container {
  width: 100%; /* Ensures container takes full width */
  padding: 20px;
  display: flex; /* Enables flexbox for centering */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically (useful if there's extra space) */
}

@media (min-width: 750px) {
  .image-container {
    flex: 1; /* Flex grow on larger screens, takes up available space */
    display: block; /* Switch to block display for side-by-side layout */
  }
}

@media (max-width: 750px) {
  #about-me-div {
    margin-bottom: -120px;
  }

  #profile-section-div {
    margin-bottom: -40px;
  }
}

.dark div {
  background-color: #333;  /* Dark background for dark mode */
  color: #fff;             /* Light text for dark mode */
}

.small-width {
  max-width: 800px;
}

#about-me-container {
  background-color: #abf7b1;
  border-radius: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 750px) {
  #about-me-container {
    border-radius: 30px;
  }
}

.dark #about-me-container{
  background-color: inherit;
}

#profile-section-div {
  flex-basis: 300px;
}

#about-me-div {
  flex-basis: 600px;
  padding: 0px 20px;
}

#profile-image {
  max-width: 100%; /* Ensures image is responsive and does not exceed container width */
  max-height: 400px; /* Maximum image height */
  height: auto; /* Maintains aspect ratio */
  border-radius: 10px; /* Optional: Adds rounded corners */
}

#brain-image {
  max-width: 150px;
  max-height: 150px;
}

#brain-div {
  flex-basis: 150px;
  max-height: 150px;
}

.card {
  flex: 0 0 auto;
  min-width: 300px;
  max-width: 500px;
  border-radius: 20px;
  margin: 20px;
  padding: 0px 20px;
  border: solid 2px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.dark .card {
  box-shadow: 0 4px 8px 0 rgba(255,255,255,0.2);
}

.card:hover {
  box-shadow: 0 12px 25px 0 rgba(0,0,0,0.4);
}

.dark .card:hover {
  box-shadow: 0 12px 25px 0 rgba(255,255,255,0.2);
}

@media (min-width: 750px) {
  .scrolling-wrapper-flexbox {  
    display: flex;  
    flex-direction: row; /* Default to vertical layout */
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    flex-wrap: nowrap;  
    overflow-x: auto;  
  }
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.projectList {
  width: 100%;
  margin-top: -20px;
  padding: 0px 20px;
}

.projectItem {
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  padding: 0px 0px;
}

.projectTitle {
  color: #333;
}

.projectDescription {
  width: 100%;
  color: #666;
}

.scroll-buffer {
  height: 50vh;
}