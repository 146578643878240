
.toggle-btn {
  background-color: #b7b9ba;
  border: 1px solid #aaa;
  border-radius: 99px;
  width: 50px;
  height: 28px;
  transition: background-color 0.1s ease, border-color 0.2s ease;
  cursor: pointer;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.748);
  position: relative;  
}

.toggle-btn .thumb {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 99px;
  transform: translateX(0);
  transition: left 0.15s ease;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.dark .toggle-btn {
  background-color: #b7b9ba;
  border: 1px solid navy;
}

.toggle-btn:hover {
  border-color: #6f6f6f;
}

.moon-icon {
  position: relative;
  top: 5px;
  right: -23px;
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 150ms;
}

.dark .toggle-btn .thumb {
  left: calc(50px - 25px);
  background-color: #808080;
}

.dark .moon-icon {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 150ms;
}

